import { Box, Button, Slide, Typography } from '@material-ui/core'
import { Link as GatsbyLink, graphql } from 'gatsby'

import { ArrowForward } from '@material-ui/icons'
import Copyright from '../components/Copyright'
import DefaultLayout from '../components/layouts/DefaultLayout'
import Img from 'gatsby-image'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    width: '100%',
    position: 'fixed',
  },
  textSplash: {
    color: theme.palette.secondary.light,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.2,
    maxWidth: '80%',
  },
  avatar: {
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary2.main}`,
  },
  siteButton: {
    color: '#000',
    backgroundColor: theme.palette.primary2.main,
  },
  copyright: {
    backgroundColor: theme.palette.primary2.main,
  },
}))

export default function Home({ data }) {
  const {
    site: { siteMetadata },
    profilePhoto,
  } = data
  const classes = useStyles()

  return (
    <DefaultLayout
      SEO={{ ...siteMetadata, title: 'Welcome' }}
      navigationBar={null}
    >
      <div className={classes.root}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="calc(100% - 50px)"
        >
          <Slide in direction="down" timeout={800}>
            <Img
              className={classes.avatar}
              fixed={profilePhoto.childImageSharp.fixed}
              alt="flvyu's profile photo"
            />
          </Slide>
          <Box
            mt={2}
            mb={4}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Slide in direction="left" timeout={600}>
              <Typography
                className={classes.textSplash}
                variant="h4"
                component="h1"
                gutterBottom
              >
                - flavio
              </Typography>
            </Slide>
            <Slide in direction="right" timeout={600}>
              <Typography className={classes.textSplash} align="center">
                Software, Thoughts, Opinions, and ...
              </Typography>
            </Slide>
          </Box>
          <Slide in direction="up" timeout={800}>
            <Button
              className={classes.siteButton}
              variant="contained"
              component={GatsbyLink}
              endIcon={<ArrowForward>Enter Site</ArrowForward>}
              to="/blog/"
            >
              Enter site
            </Button>
          </Slide>
        </Box>
        <div className={classes.copyright}>
          <Copyright />
        </div>
      </div>
    </DefaultLayout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    profilePhoto: file(relativePath: { eq: "profilepic2.png" }) {
      childImageSharp {
        fixed(width: 100, height: 100, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`