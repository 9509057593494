import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    height: 50,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    position: 'absolute',
    bottom: 5,
  },
})

export default function Copyright() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="body2" align="center">
          {`Copyright © ${new Date().getFullYear()}`} flvyu
        </Typography>
      </div>
    </div>
  )
}
